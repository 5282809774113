import { signal }           from '../eps/eps.modern.js';


const registration =        await window.ritmasServiceWorkerRegistration;
const worker = registration
    && ( registration.active
        || registration.installing
        || registration.waiting
    );

export const isAvailable =  signal( !! worker );


export const addMessageListener = fn =>
    navigator.serviceWorker?.addEventListener( 'message', fn );

export const removeMessageListener = fn =>
    navigator.serviceWorker?.removeEventListener( 'message', fn );


export const postMessage = message =>
    worker.postMessage( message );
