import clsi                 from '../clsi/clsi.js';
import {
    Component,
    html,
    signal,
}  from '../eps/eps.modern.js';

import About                from './About.js';
import Update               from './Update.js';


const isOpened =            signal( false );

const toggleMenu = () => {
    isOpened.value =        ! isOpened.value;
};

export const closeMenu = () => {
    isOpened.value =        false;
};

export default () =>
    html`<div
        className=${ clsi(
            'com-main-menu',
            isOpened.value && 'is-opened',
        )}
    >
        <button
            class="toggle"
            onClick=${ toggleMenu }
            title="Toggle main menu"
        >
            <div class="icon"><i/><i/><i/></div>
        </button>
        <menu class="menu">
            <${ Update }/>
            <${ About } onClick=${ closeMenu }/>
        </menu>
    </div>`;
