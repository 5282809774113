import clsi                 from '../clsi/clsi.js';
import { Component, html }  from '../eps/eps.modern.js';
import isOnline             from '../network/is-online.js';
import {
    addMessageListener,
    isAvailable as isSwAvailable,
    postMessage,
    removeMessageListener,
}                           from '../service-worker/store.js';


export default class MainMenuUpdate extends Component {

    state = {
        isUpdating:         false,
    };

    onClick = () => {
        console.log( 'MainMenuUpdate onClick' );
        this.setState({
            isUpdating:     true,
        });
        postMessage( 'ritmas-update' );
    };

    onWorkerMessage = e => {
        console.log( 'onWorkerMessage', e.data );
        if( e.data === 'ritmas-update-done' ){
            window.location.reload();
        }
    };

    componentDidMount() {

        addMessageListener( this.onWorkerMessage );
    }

    componentWillUnmount() {

        removeMessageListener( this.onWorkerMessage );
    }

    render( _, { isUpdating }) {
        return isSwAvailable.value
            ? html`<button
                class=${ clsi(
                    'com-main-menu-update',
                    isUpdating && 'is-updating',
                ) }
                disabled=${ ! isOnline.value || isUpdating }
                onClick=${ this.onClick }
            >
                ${ isUpdating ? 'Updating...' : 'Update' }
            </buton>`
            : null;
    }
}
