/*
    This file is part of Ritmas.eu.

    Ritmas.eu is free software: you can redistribute it and/or modify
    it under the terms of the GNU Affero General Public License as
    published by the Free Software Foundation, either version 3 of the
    License, or (at your option) any later version.

    Ritmas.eu is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU Affero General Public License for more details.

    You should have received a copy of the GNU Affero General Public License
    along with Ritmas.eu. If not, see <https://www.gnu.org/licenses/>.
*/
import {
    average,
    maxBy,
    minBy,
    range,
}                           from '../array/index.js';

import {
    ANY_METRE,
    LESS_THAN_16_STEPS,
    NO_SWING,
}                           from './constants.js';
import timings              from './timings.js';
//  console.log( timings );


const STEP_COUNT =          16;
const STEP_HALF_LENGTH =    0.5 / STEP_COUNT;
const STEP_LENGTH =         1 / STEP_COUNT;
const TIMINGS = range( STEP_COUNT ).map( n =>
    n / STEP_COUNT
);


export default ({
    barCount,
    metreFilter,
    repeats,
    taps,
}) => {
    if( ! taps.length ){
        throw Error( 'Tap in the rhyrhm first.' );
    } else if( taps.length % repeats ){
        throw Error( `Incorrect number of taps. Should be divisible by ${ repeats }, found ${ taps.length }.` );
    }

    const firstTap =        taps[0];
    const seqLength =       taps.length / repeats;
    const seqDuration = average(
        taps.slice( seqLength )
            .map(( tap, i ) =>
                 tap - taps[i]
            )
    );
    const barDuration =     seqDuration / barCount;

    const seqTaps =         Array( seqLength );
    for( let si = 0; si < seqLength; si++ ){
        seqTaps[si] =       Array( repeats );
        for( let ri = 0; ri < repeats; ri++ ){
            const tapTime =     taps[ ri * seqLength + si ] - firstTap;
            seqTaps[si][ri] = ( tapTime - seqDuration * ri ) / barDuration;
        }
    }
    const avgSequenceTaps = seqTaps.map( average );
    //  console.log( avgSequenceTaps );

    const timingsWithBars =
        timings.filter( timing =>
            metreFilter === ANY_METRE
                ? true
            : metreFilter === LESS_THAN_16_STEPS
                ? timing.stepCount <= 16
                : timing.metre === metreFilter
        ).map( timing => ({
            ...timing,
            bars: range( barCount ).map( barIndex =>
                timing.steps.map(( step, stepIndex ) => ({
                    ...step,
                    taps: avgSequenceTaps.map( avgTapTime =>
                            avgTapTime - barIndex
                        ).filter( avgTapTimeInBar =>
                            avgTapTimeInBar > step.minTime
                            && avgTapTimeInBar <= step.maxTime
                        ),
                }))
            ),
        }));
    //  console.log( timingsWithBars );

    const matchedTiming = maxBy(
        ( timing, tIndex ) => {
            let onBeatCount =           0;
            let onEighthCount =         0;
            let multipleBeatsPenalty =  0;
            let timeDifference =        0;

            for( const bar of timing.bars ){
                for( const step of bar ){
                    if( step.taps.length ){
                        if( step.isOnBeat ){
                            onBeatCount++;
                        } else if( step.isOnEighth ){
                            onEighthCount++;
                        }
                        if( step.taps.length > 1 ){
                            multipleBeatsPenalty += step.taps.length;
                        }
                        for( const tapTime of step.taps ){
                            timeDifference +=   Math.abs( tapTime - step.time );
                        }
                    }
                }
            }


            const result = (
                ( onBeatCount + onEighthCount )     //  [0..seqLength]
                + onBeatCount / seqLength           //  [0..1]
                - multipleBeatsPenalty              //  [0..seqLength]
                - timeDifference                    //  [0..barCount / 2]
                - (
                    timing.swing.level === 2
                        ? seqLength === onBeatCount
                    : timing.swing.level === 1
                        ? seqLength === ( onBeatCount + onEighthCount )
                        : 0
                )                                   //  [0..1]
                - tIndex / 1000                     //  [0..~0.1]
            );
            /*
            console.log(
                timing.metre,
                timing.swing.amount,
                timing.swing.level,
                seqLength,
                onBeatCount,
                onEighthCount,
                2 * timeDifference,
                result,
            );
            */
            return result;
        },
        timingsWithBars,
    );
    //  console.log( matchedTiming );

    return {
        bars:               matchedTiming.bars,
        bpm: Math.round(
            60e3 * 4
            / ( barDuration * 16 / matchedTiming.stepCount )
        ),
        metre:              matchedTiming.metre,
        swing: matchedTiming.swing.level
            ? `${ matchedTiming.swing.amount }% ${ matchedTiming.swing.level === 2 ? '8ths' : '16ths' }`
            : NO_SWING,
    };
}
