import {
    ANY_METRE,
    DEFAULT_METRE,
    LESS_THAN_16_STEPS,
}                           from '../analysis/constants.js';
import { Component, html }  from '../eps/eps.modern.js';
import knownMetres          from '../analysis/known-metres.js';
import Select               from '../select/Select.js';

import {
    barCount,
    barLabel,
    metreFilter,
    onChangeBarCount,
    onChangeMetreFilter,
    onChangeRepeats,
    repeats,
}                           from './store.js';


const BAR_OPTIONS = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
}
const METRE_OPTIONS = {
    [ANY_METRE]:            'Detect meter',
    [LESS_THAN_16_STEPS]:   '≤ 16 steps',
    [DEFAULT_METRE]:        DEFAULT_METRE,
    ...Object.fromEntries(
        knownMetres
            .filter( metre =>
                metre !== DEFAULT_METRE
            ).sort(( a, b ) =>
                parseInt( a.split( '/' )[0] )
                - parseInt( b.split( '/' )[0] )
            ).map( metre => [ metre, metre ])
    ),
};
const REPEAT_OPTIONS = {
    2: 2,
    3: 3,
    4: 4,
};


export default class Parameters extends Component {

    onChangeBarCount = evt =>
        onChangeBarCount( parseInt( evt.target.value, 10 ));

    onChangeMetreFilter = evt =>
        onChangeMetreFilter( evt.target.value );

    onChangeRepeats = evt =>
        onChangeRepeats( parseInt( evt.target.value, 10 ));

    render() {
        return html`<div class="com-parameters">
            <${Select}
                className="bars"
                labelPostfix=${ barLabel.value }
                onChange=${ this.onChangeBarCount }
                options=${ BAR_OPTIONS }
                value=${ barCount.value }
            />
            <${Select}
                className="repeats"
                labelPostfix="times"
                onChange=${ this.onChangeRepeats }
                options=${ REPEAT_OPTIONS }
                value=${ repeats.value }
            />
            <${Select}
                className="metre"
                onChange=${ this.onChangeMetreFilter }
                options=${ METRE_OPTIONS }
                value=${ metreFilter.value }
            />
        </div>`;
    }
}
