import { computed, signal } from '../eps/eps.modern.js';


export const taps =         signal( [] );
export default taps;

export const hasEnoughTaps = computed(() =>
    taps.value.length > 1
);


export const addTap = tap =>
    taps.value =            [ ...taps.value, tap ];


export const resetTaps = () =>
    taps.value =            [];
