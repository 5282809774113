import { html }             from '../eps/eps.modern.js';
import { ON_TOUCH_START }   from '../browser/index.js';
import {
    barCount,
    barLabel,
    repeats,
}                           from '../parameters/store.js';


export default ({ isDisabled, onTap }) =>
    html`
        <button
            class="com-tap-controls-drum"
            disabled=${ isDisabled }
            ...${{ [ON_TOUCH_START]: isDisabled ? undefined : onTap }}
        >
            <div class="visual">
                <span>
                    Tap in
                    <strong> ${ barCount } </strong>
                    ${ barLabel.value }
                    <strong> ${ repeats } </strong>
                    times
                </span>
            </div>
        </button>
    `;
