import {
    barCount,
    metreFilter,
    repeats,
}                           from '../parameters/store.js';
import { createSteps }      from '../analysis/timings.js';
import findTiming           from '../analysis/find-timing.js';
import { repeat }           from '../array/index.js';
import { signal }           from '../eps/eps.modern.js';
import taps                 from '../taps/store.js';

//  Constants ------------------------------------------------------------------

const BPM_WAIT =            '...';
const EMPTY_BARS = [ createSteps({
    beatIndices:            [ 0, 4, 8, 12 ],
    stepCount:              16,
    swing:                  50,
})];
const METRE_WAIT =          '...';
const SWING_WAIT =          '...';

//  Signals --------------------------------------------------------------------

export const analysisError =    signal( null );
export const bars =             signal( EMPTY_BARS );
export const bpm =              signal( BPM_WAIT );
export const metre =            signal( METRE_WAIT );
export const swing =            signal( SWING_WAIT );

//  Actions --------------------------------------------------------------------

export const analyze = () => {
    try {
        const result = findTiming({
            barCount:           barCount.value,
            metreFilter:        metreFilter.value,
            repeats:            repeats.value,
            taps:               taps.value,
        });
        analysisError.value =   null;
        bars.value =            result.bars;
        bpm.value =             '~' + result.bpm;
        metre.value =           result.metre;
        swing.value =           result.swing;

    } catch( err ){

        analysisError.value =   err.message;
        console.error( err );
    }
}

export const resetResults = () => {

    bars.value =                EMPTY_BARS;
    bpm.value =                 BPM_WAIT;
    analysisError.value =       null;
    metre.value =               METRE_WAIT;
    swing.value =               SWING_WAIT;
}
