import { addTap }           from '../taps/store.js';
import { Component, html }  from '../eps/eps.modern.js';
import { hasEnoughTaps }    from '../taps/store.js';
import { ON_TOUCH_END }     from '../browser/index.js';

import Drum                 from './Drum.js';
import {
    isStarted,
    onClickCount,
    onClickRestart,
}                           from './store.js';


export default class TapControls extends Component {

    onTapDrum = () =>
        addTap( performance.now() );

    render() {
        return html`
            <div class="com-tap-controls">
                <${ Drum }
                    isDisabled=${ ! isStarted.value }
                    onTap=${ this.onTapDrum }
                />
                <button
                    class="count-restart"
                    disabled=${ ! hasEnoughTaps.value }
                    ...${{ [ON_TOUCH_END]:
                        isStarted.value ? onClickCount : onClickRestart
                    }}
                >
                    ${ isStarted.value ? 'Count them beats' : 'Restart' }
                </button>
            </div>
        `;
    }
}
