export const maxBy = ( fn, arr ) => {
    if( arr.length < 1 ){
        return undefined;
    } else if( arr.length === 1 ){
        return arr[0];
    } else {
        let maxItem =       arr[0];
        let maxValue =      fn( maxItem, 0, arr );
        for( let i = 1; i < arr.length; i++ ){
            const value =   fn( arr[i], i, arr );
            if( value > maxValue ){
                maxValue =  value;
                maxItem =   arr[i];
            }
        }
        return maxItem;
    }
};

export const minBy = ( fn, arr ) => {
    if( arr.length < 1 ){
        return undefined;
    } else if( arr.length === 1 ){
        return arr[0];
    } else {
        let minItem =       arr[0];
        let minValue =      fn( minItem, 0, arr );
        for( let i = 1; i < arr.length; i++ ){
            const value =   fn( arr[i], i, arr );
            if( value < minValue ){
                minValue =  value;
                minItem =   arr[i];
            }
        }
        return minItem;
    }
};


export const range = n => {
    const result =      Array( n );
    for( let i = 0; i < n; i++ ){
        result[i] =     i;
    }
    return result;
};


export const repeat = ( value, n ) =>
    [ ...Array( n ).values() ].map(
        () => value
    );


export const sum = arr => {
    let sum =           0;
    for( const n of arr ){
        sum +=          n;
    }
    return sum;
};


export const uniq = arr =>
    [ ...new Set( arr )];


export const average = arr =>
    sum( arr ) / arr.length;
