export {
    Component,
    h,
    render,
}                           from 'preact';

export {
    batch,
    computed,
    effect,
    signal,
}                           from '@preact/signals';

import { h }                from 'preact';
import htm                  from 'htm';

export const html =         htm.bind( h );
