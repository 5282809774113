import { html }             from '../eps/eps.modern.js';
import MainMenu             from '../main-menu/MainMenu.js';

import Intro                from './Intro.js';


export default () =>
    html`
        <header class="com-header">
            <img
                alt="Ritmas.eu"
                class="logo"
                src="header/logo.svg"
            />
            <${ Intro }/>
            <${ MainMenu }/>
        </header>
    `;
