import { effect }           from '../eps/eps.modern.js';

import {
    barCount,
    metreFilter,
    repeats,
}                           from './store.js';


const KEY =                 'parameters/save-state';


try {
    const values =          JSON.parse( localStorage.getItem( KEY ));
    if( values ){
        barCount.value =    values.barCount || barCount.value;
        metreFilter.value = values.metreFilter || metreFilter.value;
        repeats.value =     values.repeats || repeats.value;
    }
} catch( err ){
    //  do nothing.
}


effect(() => {
    try {
        localStorage.setItem(
            KEY,
            JSON.stringify({
                barCount:       barCount.value,
                metreFilter:    metreFilter.value,
                repeats:        repeats.value,
            }),
        );
    } catch( err ){
        //  do nothing.
    }
});
