import { closeMenu }        from '../main-menu/MainMenu.js';
import clsi                 from '../clsi/clsi.js';
import { html, signal }     from '../eps/eps.modern.js';


const content =             signal( null );

export const closeModal = () => {
    content.value =         null;
};

export const replaceModalContent = newContent => {
    closeMenu();
    content.value =         newContent;
};


const stopPropagation = evt => {
    evt.stopPropagation();
};


export default ({ className }) =>
    html`<div
        class=${ clsi(
            'com-modal',
            content.value && 'is-opened',
            className,
        )}
        onClick=${ closeModal }
    >
        <div
            class="content-container"
            onClick=${ stopPropagation }
        >
            <button
                children="✕"
                class="close"
                onClick=${ closeModal }
            />
            <div class="content">
                ${ content.value }
            </div>
            <button
                children="close"
                class="close-bottom"
                onClick=${ closeModal }
            />
        </div>
    </div>`;

