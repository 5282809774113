import { signal }           from '../eps/eps.modern.js';


const isOnline =            signal( navigator.onLine );

window.addEventListener( 'offline', () => {
    isOnline.value =        false;
});

window.addEventListener( 'online', () => {
    isOnline.value =        true;
});


export default isOnline;
