import {
    batch,
    signal,
}                           from '../eps/eps.modern.js';
import {
    analyze,
    resetResults,
}                           from '../results/store.js';
import {
    hasEnoughTaps,
    resetTaps,
}                           from '../taps/store.js';


export const isStarted =    signal( true );


export const onClickCount = () => {
    if( hasEnoughTaps.value ){
        isStarted.value =   false;
        analyze();
    }
}

export const onClickRestart = () => {
    batch(() => {
        isStarted.value =   true;
        resetTaps();
        resetResults();
    });
}
