import clsi                 from '../clsi/clsi.js';
import { html }             from '../eps/eps.modern.js';

import Step                 from './Step.js';


export default ({ isReducedHeight, steps }) =>
    html`<ol class=${ clsi(
            'com-results-bar',
            isReducedHeight && 'is-reduced-height',
            steps.length > 24
                ? 'rows-4'
            : steps.length > 16
                ? 'rows-3'
            : steps.length > 8
                ? 'rows-2'
                : 'rows-1'
    )}>${
        steps.map(( step, index ) =>
            Step({
                key:        index,
                step,
            })
        )
    }</ol>`;
