import { analyze }          from '../results/store.js';
import { computed, signal } from '../eps/eps.modern.js';
import { DEFAULT_METRE }    from '../analysis/constants.js';
import { hasEnoughTaps }    from '../taps/store.js';
import { isStarted }        from '../tap-controls/store.js';


const analyzeOnChange = () => {
    if( hasEnoughTaps.value && ! isStarted.value ){
        analyze();
    }
};


export const barCount =     signal( 1 );

export const metreFilter =  signal( DEFAULT_METRE );

export const repeats =      signal( 2 );


export const barLabel = computed(() =>
    barCount.value === 1 ? 'bar' : 'bars'
);


export const onChangeBarCount = value => {
    barCount.value =        value;
    analyzeOnChange();
}

export const onChangeMetreFilter = value => {
    metreFilter.value =     value;
    analyzeOnChange();
}

export const onChangeRepeats = value => {
    repeats.value =         value;
    analyzeOnChange();
}
