import { html }             from '../eps/eps.modern.js';
import {
    replaceModalContent,
}                           from '../modal/Modal.js';


const onClick = () =>
    replaceModalContent( html`
        <p>This app has some limitations:</p>

        <h3>1. You have to start with a tap</h3>
        <p>There must be a beat on the first step of the first bar.</p>
        <p>If the rhythm you are trying to tap does not have it, you'll have to add it and ignore in the result.</p>

        <h3>2. You have to repeat it at least once</h3>
        <p>This is needed to get the length of a bar and to average out timing mistakes.</p>

        <h3>3. You need to be precise</h3>
        <p>Make enough mistakes in timing the taps and you'll end up with a different meter.</p>
        <p><strong>Advice:</strong> tap the rhythm somewhere before tapping it on the Tap button. I do it on the non-screen area of my phone. You can also do it on the step sequencer display.</p>

        <h3>Other limitations</h3>
        <p>These may change in the future:</p>
        <ul>
            <li>No triplet (or other tuplet) support.</li>
            <li>Limited number of meters supported.</li>
            <li>Unreliable swing detection.</li>
            <li>16th is the smallest duration.</li>
            <li>No distinction between accented and non-accented beats.</li>
        </ul>
    ` );


export default () =>
    html`<button
        class="com-header-intro"
        onClick=${ onClick }
    >
        <span>i</span>
    </button>`;
