import Header               from '../header/Header.js';
import { html }             from '../eps/eps.modern.js';
import Modal                from '../modal/Modal.js';
import Parameters           from '../parameters/Parameters.js';
import Results              from '../results/Results.js';
import TapControls          from '../tap-controls/TapControls.js';


export default () =>
    html`
        <div class="com-app">
            <${ Header }/>
            <${ Results }/>
            <${ TapControls }/>
            <${ Parameters }/>
            <${ Modal } />
        </div>
    `;
