import clsi                 from '../clsi/clsi.js';
import { html }             from '../eps/eps.modern.js';
import { isStarted }        from '../tap-controls/store.js';

import {
    analysisError,
    bars,
    bpm,
    metre,
    swing,
}                           from './store.js';
import Bar                  from './Bar.js';


export default () =>
    html`
        <div class="com-results">
            <div class=${ clsi(
                'display',
                isStarted.value && 'is-waiting',
            )}>
                ${ analysisError.value
                    ? html`<div class="error">${ analysisError }</div>`
                    : html`<div class="values">
                        <dl><dt>BPM:</dt><dd>${ bpm }</dd></dl>
                        <dl><dt>Meter:</dt><dd>${ metre }</dd></dl>
                        <dl class="swing"><dt>Swing:</dt><dd>${ swing }</dd></dl>
                    </div>`
                }
            </div>
            ${ bars.value.map(( steps, index ) =>
                Bar({
                    isReducedHeight:    bars.value.length > 1,
                    key:                index,
                    steps,
                })
            )}
        </div>
    `;
