/*
    This file is part of Ritmas.eu.

    Ritmas.eu is free software: you can redistribute it and/or modify
    it under the terms of the GNU Affero General Public License as
    published by the Free Software Foundation, either version 3 of the
    License, or (at your option) any later version.

    Ritmas.eu is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU Affero General Public License for more details.

    You should have received a copy of the GNU Affero General Public License
    along with Ritmas.eu. If not, see <https://www.gnu.org/licenses/>.
*/
import { range }            from '../array/index.js';

const timesCache =          new Map;


const createStepTimes = ( stepCount, swing ) => {

    const cacheIndex =      `${ stepCount }/${ swing.amount }/${ swing.length }`;

    if( timesCache.has( cacheIndex )){
        return timesCache.get( cacheIndex );
    }

    const equalTimes = stepIndex =>
        stepIndex / stepCount;

    const swing16 = stepIndex => (
        stepIndex / stepCount
        + ( stepIndex % 2
           ? ( 1 / stepCount ) * ( swing.amount - 50 ) / 100
           : 0
        )
    );

    const swing8 = stepIndex => {

        const indexInBeat =     stepIndex % 4;
        const stepDuration =    1 / stepCount;
        const swingDuration =   stepDuration * ( swing.amount - 50 ) / 100;

        return stepIndex / stepCount + (
            indexInBeat === 0   ? 0
            : indexInBeat === 1 ? swingDuration / 2
            : indexInBeat === 2 ? swingDuration
            : indexInBeat === 3 ? swingDuration / 2
            : NaN
        );
    };

    return timesCache
        .set(
            cacheIndex,
            range( stepCount ).map(
                swing.level === 2
                    ? swing8
                : swing.level === 1
                    ? swing16
                    : equalTimes
            ),
        ).get( cacheIndex );
};

export const createSteps = ({
    beatIndices,
    stepCount,
    swing,
}) => {
    const lastIndex =       stepCount - 1;
    const stepTimes =       createStepTimes( stepCount, swing );
    const divisionTimes =   [
        0 - ( 1 - stepTimes[ lastIndex ]) / 2,
        ...stepTimes.slice( 1 ).map(( time, i ) =>
            stepTimes[i] + ( time - stepTimes[i] ) / 2
        ),
        1 - ( stepTimes[1] - stepTimes[0] ) / 2,
    ];
    return stepTimes.map(( time, i ) => {
        const isOnBeat =    beatIndices.includes( i );
        return {
            isOnBeat,
            isOnEighth:     ! isOnBeat && ! ( i % 2 ),
            maxTime:        divisionTimes[i+1],
            minTime:        divisionTimes[i],
            time,
        };
    });
};

const createTiming = ({
    beatIndices,
    metre,
    stepCount,
    swing,
}) => {
    const steps = createSteps({
        beatIndices,
        stepCount,
        swing,
    });
    return {
        maxBeats:           steps.filter( s => s.isOnBeat ).length,
        maxEighths:         steps.filter( s => s.isOnEighth ).length,
        metre,
        stepCount,
        steps,
        swing,
    };
}

const SWING_AMOUNTS = [
    54, 57, 60, 62, 66, 70, 75,
    46, 43, 40, 38, 33, 30, 25,
];
const createSwings = line => [
    `${ line } 50 0`,
    ...SWING_AMOUNTS.map( amount =>
        [ 2, 1 ].map( level =>
            `${ line } ${ amount } ${ level }`
        )
    ).flat( 2 )
].join( '\n' );


export default `
    ${ createSwings(   '4/4     16  0,4,8,12'           )}
                        2/4     8   0,4                 50  0
    ${ createSwings(   '3/4     12  0,4,8'              )}
                        6/8     12  0,6                 50  0
                        9/8     18  0,6,12              50  0
                        5/8     10  0,6                 50  0   3-2
                        5/8     10  0,4                 50  0   2-3
                        5/8     10  0,4,6               50  0   2-1-2
                        5/8     10  0,4,8               50  0   2-2-1
                        5/8     10  0,2,6               50  0   1-2-2
                        5/8     10  0,4,6,8             50  0   2-1-1-1
                        5/8     10  0,2,6,8             50  0   1-2-1-1
                        5/8     10  0,2,4,8             50  0   1-1-2-1
                        5/8     10  0,2,4,6             50  0   1-1-1-2
                        5/8     10  0,2,4,6,8           50  0   1-1-1-1-1
                        7/8     14  0,4,8               50  0   2-2-3
                        7/8     14  0,6,10              50  0   3-2-2
                        7/8     14  0,4,10              50  0   2-3-2
                        7/4     28  0,4,8,12,16,20,24   50  0
                        12/8    24  0,6,12,18           50  0
`.trim().split( '\n' )
    .map( line =>
         line.trim().split( /\s+/g )
    ).map( parts =>
        createTiming({
            metre:          parts[0],
            stepCount:      parseInt( parts[1], 10 ),
            beatIndices:
                parts[2].split( ',' ).map( index =>
                    parseInt( index, 10 )
                ),
            swing: {
                amount:     parseInt( parts[3], 10 ),
                level:      parseInt( parts[4], 10 ),
            },
        })
    );
