export const IS_TOUCH_DEVICE =  matchMedia( '(hover: none)' ).matches;

export const ON_TOUCH_END =
    IS_TOUCH_DEVICE
        ? 'ontouchend'
        : 'onmouseup';

export const ON_TOUCH_START =
    IS_TOUCH_DEVICE
        ? 'ontouchstart'
        : 'onmousedown';
