import { Component, html }  from '../eps/eps.modern.js';
import {
    replaceModalContent,
}                           from '../modal/Modal.js';


const TEXT_HTML = html`
    <h2>About</h2>
    <p>Ritmas.eu is free software licensed under
        ${' '}
        <a href="https://www.gnu.org/licenses/agpl-3.0.en.html">AGPLv3 license</a>
    .</p>
    <p>Copyright 2023 Emilis Dambauskas.</p>
    <p><strong>Source code:</strong> <a href="https://codeberg.org/emilis/ritmas">codeberg.org/emilis/ritmas</a>.</p>
    <p>Please report <strong>bugs</strong> and <strong>feature requests</strong> <a href="https://codeberg.org/emilis/ritmas/issues">here</a>.</p>
    <h3>Contact</h3>
    <p><strong>Email:</strong>
        ${' '}
        <a href="mailto:emilis@tonnetz.eu">emilis@tonnetz.eu</a>
    .</p>
    <p><strong>Mastodon:</strong>
        ${' '}
        <a href="https://fosstodon.org/@emilis">@emilis@fosstodon.org</a>
    .</p>
    <p>I develop Ritmas.eu in my free time, so please be patient when waiting for an answer.</p>
    <p>If you found this useful, please support further development:
        <a
            className="com-main-menu-about--lp-donate"
            href="https://liberapay.com/emilis/donate"
        >
            <img
                align="absmiddle"
                alt="Donate using Liberapay"
                height="30"
                src="https://liberapay.com/assets/widgets/donate.svg"
                width="83"
            />
        </a>
    </p>
`;


export default class MainMenuAbout extends Component {

    onClick = () => {
        replaceModalContent( TEXT_HTML );
        this.props.onClick?.();
    };

    render() {
        return html`<button onClick=${ this.onClick }>
            About & Contacts
        </button>`;
    }
}
