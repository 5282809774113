import clsi                 from '../clsi/clsi.js';
import { html }             from '../eps/eps.modern.js';


const TimeBar = ({ step, tapTime }) =>
    html`<div
        class="time-bar"
        style=${{
            left: `calc( ${
                tapTime <= step.time
                    ? 50 - 50 * ( step.time - tapTime ) / ( step.time - step.minTime )
                    : 50 + 50 * ( tapTime - step.time ) / ( step.maxTime - step.time )
            }% - 1.5px )`
        }}
    />`;


export default ({ step }) =>
    html`
        <li
            class=${ clsi(
                'com-results-step',
                step.taps?.length > 1 && 'has-multiple-taps',
                step.taps?.length && 'is-active',
                step.isOnBeat && 'is-on-beat',
            )}
        >
            ${ step.taps?.map( tapTime =>
                TimeBar({ step, tapTime })
            ) }
        </li>
    `;
