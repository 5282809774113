import clsi                 from '../clsi/clsi.js';
import { html }             from '../eps/eps.modern.js';


export default ({
    className,
    labelPostfix =          '',
    labelPrefix =           '',
    onChange,
    options,
    value
}) =>
    html`<div
        class=${ clsi(
            'com-select',
            className,
        )}
    >
        <select onChange=${ onChange } value=${ value }>
            ${ Object.entries( options ).map(
                ([ optionValue, label ]) =>
                    html`<option
                        children=${ label }
                        value=${ optionValue }
                        selected=${ optionValue === '' + value }
                    />`
            ) }
        </select>
        <div class="label">
            ${ labelPrefix } ${ options[value] } ${ labelPostfix }
            <b dangerouslySetInnerHTML=${{ __html: '&nbsp;▾' }} />
        </div>
    </div>`;
